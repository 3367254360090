import React, {Component} from 'react'
// import {Link} from 'react-router-dom';
import UXDesignWaffler from './UXDesignWaffler';

class UXDesignContainer extends Component {

    render(){
        return(
            <div>
                <UXDesignWaffler />
            </div>
        )
    }
}

export default UXDesignContainer;